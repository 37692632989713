import React from 'react'
import Gallery from 'react-photo-gallery'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import owner from '../images/owner.jpeg'
import degree from '../images/degree.png'
import coryWorking from '../images/coryworking.jpeg'

import './about.scss'

const copyOne = `
Cory Bott comes from a long line of family working in the welding industry. After shadowing Dick Snow in Springville, UT for a day, Cory decided to learn more about repairing welding equipment by becoming factory-trained at ESAB, which lead to the development of Rocky Mountain Torch.
`
const copyTwo = `
Rocky Mountain Torch strives to provide services to continue to elevate the industry standard in welding. The mission of Rocky Mountain Torch is to allow welders and businesses alike to be able to function fully without concern for their equipment. 
`
const copyThree = `
At Rocky Mountain Torch, we know that the best equipment is equipment you don’t have to think or worry about. Call today to schedule a local pick-up or arrange for the fastest repairs possible by mail.
`

const photoOne = [
  {
    src: owner,
    width: 1,
    height: 1,
  },
]

const photoTwo = [
  {
    src: degree,
    width: 1,
    height: 1,
  },
]

const photoThree = [
  {
    src: coryWorking,
    width: 1,
    height: 1,
  },
]

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="about-section">
      <div className="about-picture-row">
        <span className="picture-side">
          <Gallery photos={photoOne} />
        </span>
        <span className="copy-side">
          &nbsp;&nbsp;<p>{copyOne}</p>
        </span>
      </div>
      <br />
      <div className="about-picture-row">
        <span className="copy-side">
          &nbsp;&nbsp;<p>{copyTwo}</p>
        </span>
        <span className="picture-side">
          <Gallery photos={photoThree} />
        </span>
      </div>
      <div className="about-picture-row">
        <span className="picture-side">
          <Gallery photos={photoTwo} />
        </span>
        <span className="copy-side">
          &nbsp;&nbsp;<p>{copyThree}</p>
        </span>
      </div>
    </section>
  </Layout>
)

export default AboutPage
